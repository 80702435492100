import React from "react";
import "./AboutMe.css";
import Author from "../Images/Devon.jpg";

const AboutMe = () => {
  return (
    <div id="about" className="container about-me-section py-5">
      <div className="row">
        <div className="col-lg-6 col-xm-12">
          <div className="photo-wrap mb-5">
            <img className="profile-img" src={Author} alt="Author" />
          </div>
        </div>
        <div className="col-lg-6 col-xm-12 about-text">
          <h1 className="about-heading">About Me</h1>
          <br/>
          <p>
            Hi, I'm Devon! I am a self-motivated and competent
            individual, I have experience writing codes/programs and formulating
            functional components. I remain influential in leveraging all
            functions related to application support, site troubleshooting, web
            development, and system integrations. With the capability to
            maintain service quality, I am skillful at establishing
            communication channels and achieving defined goals. Having a Graduate
            Certificate in Full Stack Web Development has extraordinarily
            assisted me in enhancing my knowledge in coding and programming,
            performance testing, requirement analysis, and technical needs
            assessments. Some of my core competencies include but are not
            limited to the following:
            <br />
            <br />
            Coding & Programming <span>|</span> Performance Testing <span>|</span> Process Improvement <br/>
            <span>|</span> &nbsp;Requirement Analysis <span>|</span> Customer Satisfaction
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutMe;
