import React from "react";
import "./Experience.css";
import Resume from "../Files/Devon Hurteau Resume.pdf";

const Experience = () => {
  return (
    <div id="experience" className="experience">
      <div className="d-flex justify-content-center my-5">
        <h1>experience</h1>
      </div>
      <div className="container experience-wrapper">
        <div className="timeline-block timeline-block-right">
          <div className="marker"></div>
          <div className="timeline-content">
            <h3>2021-2022</h3>
            <p>
              Mid 2021, I started my programming journey. I started by self
              teaching myself C# basics and SQL Sever. Whilst working in the position of Aircraft Load
              Coordinator at Perimeter Aviation, I noticed that the systems and procedures were
              outdated. I Designed and implemented a system allowing multiple
              departments to communicate through one platform. This program
              greatly increased both productivity and allowed for more effective
              and clear communication.<br/><br/>
              In September of 2021 I enrolled in the Full Stack Web Development course at Roberston College.
            </p>
          </div>
        </div>
        {/* - */}
        <div className="timeline-block timeline-block-left">
          <div className="marker"></div>
          <div className="timeline-content">
            <h3>2022-2023</h3>
            <p>
              In June 2022, I graduate Full Stack Web Development course at Robertson College. 
              During the year 2022, I've also created this Portfolio website and
              improved on the Perimeter Threshold application.
            </p>
          </div>
        </div>
      </div>
      <div className="text-center btn-section">
        <a href={Resume} download="Devon's Resume">
          <button className="resume-btn text-center">Download Resume</button>
        </a>
      </div>
    </div>
  );
};

export default Experience;
