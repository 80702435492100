import './App.css';
import "bootstrap/dist/css/bootstrap.min.css";
import NavbarComp from './Components/NavbarComp';
import Header from './Components/Header';
import AboutMe from './Components/AboutMe';
import Experience from './Components/Experience';
import Footer from './Components/Footer';
import Contact from './Components/Contact';
import Projects from './Components/Projects';

function App() {
    return (
      <>
        <NavbarComp />
        <Header />
        <AboutMe />
        <Experience />
        <Projects />
        <Contact />
        <Footer />
      </>
    );
  }

  export default App;
