import React from "react";
import "./Footer.css";
import {
  MDBFooter,
  MDBContainer,
  MDBIcon,
} from "mdb-react-ui-kit";


const Footer = () => {
  return (
    <MDBFooter className="text-center text-white">
      <MDBContainer className="p-4">
        <section className="mb-0 footer-section">
          <a
            className="btn btn-outline-light btn-floating m-1"
            href="https://www.linkedin.com/in/devonhurteau" rel="noreferrer" target={"_blank"}
            role="button"
          >
            <MDBIcon fab icon="linkedin-in fa-lg" className="icon-color" />
          </a>

          <a
            className="btn btn-outline-light btn-floating m-1"
            href="https://github.com/Hurteau101" rel="noreferrer" target={"_blank"}
            role="button"
          >
            <MDBIcon fab icon="github fa-lg" className="icon-color"/>
          </a>
        </section>
      </MDBContainer>
    </MDBFooter>
  );
};

export default Footer;
