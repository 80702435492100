import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import "./NavbarComp.css";

import { Link } from "react-scroll";

const NavbarComp = () => {
  return (
    <div>
      <Navbar className="fixed-top" bg="dark" expand="lg" variant="light">
        <Container fluid>
          <Navbar.Brand className="nav-logo" href="#">
            Devon<span className="header-space">Hurteau</span>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ms-auto my-2 my-lg-0">             
              <li>
                <Link
                  activeClass="active"
                  className="link"
                  spy={true}
                  smooth={true}
                  to="home"
                  offset={-100}
                  duration={1000}
                >
                  Home
                </Link>
              </li>
              <li>
                <Link
                  activeClass="active"
                  className="link"
                  spy={true}
                  smooth={true}
                  to="about"
                  offset={-60}
                  duration={1000}
                >
                  About
                </Link>
              </li>
              <li>
                <Link
                  activeClass="active"
                  className="link"
                  spy={true}
                  smooth={true}
                  to="experience"
                  offset={-58}
                  duration={1000}
                >
                  Experience
                </Link>
              </li>
              <li>
                <Link
                  activeClass="active"
                  className="link"
                  spy={true}
                  smooth={true}
                  to="projects"
                  offset={-95}
                  duration={1000}
                >
                  Projects
                </Link>
              </li>
              <li>
                <Link
                  activeClass="active"
                  className="link"
                  spy={true}
                  smooth={true}
                  to="contact"
                  offset={-25}
                  duration={1000}
                >
                  Contact
                </Link>
              </li>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default NavbarComp;
