import React from "react";
import cargoBoard from "../Images/Cargo-Board.jpg";
import rampBoard from "../Images/Ramp-Board.jpg";
import loadplanner from "../Images/Loadplan.jpg";
import "./Projects.css";

const Projects = () => {
  return (
    <div id="projects" class="container service">
      <div className="text-center title m-5">
        <h2>Projects</h2>
      </div>
      <div className="row">
        <div className="col-lg-4">
          <div className="box">
            <div className="card">
              <img src={rampBoard} alt="Cargo Board" className="card-img-top project-image"/>
              <h5>Threshold Ramp Board</h5>
              <div className="description">
                <p>
                  Threshold Ramp Board is designed to track outbound and inbound
                  flights for the ground crew.
                </p>
                <p>
                  <a
                    className="button"
                    href="http://www.perimeterthreshold.ca"
                    rel="noreferrer"
                    target={"_blank"}
                  >
                    Visit Threshold
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
        {/*-----BREAK-----*/}
        <div className="col-lg-4">
          <div className="box">
            <div className="card">
              <img src={cargoBoard} alt="Cargo Board" className="card-img-top project-image"/>
              <h5>Threshold Cargo Board</h5>
              <div className="description">
                <p>
                  Threshold Cargo Board is designed to provide the total cargo
                  payload to the ground crew.
                </p>
                <p>
                  <a
                    className="button"
                    href="http://www.perimeterthreshold.ca"
                    rel="noreferrer"
                    target={"_blank"}
                  >
                    Visit Threshold
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
        {/*-----BREAK-----*/}
        <div className="col-lg-4">
          <div className="box">
            <div className="card">
              <img src={loadplanner} alt="Cargo Board" className="card-img-top project-image"/>
              <h5>Threshold Loadplanner</h5>
              <div className="description">
                <p>
                  Threshold Loadplanner is designed to calculate weight and
                  balance for aircrafts.
                </p>
                <p>
                  <a
                    className="button"
                    href="http://www.perimeterthreshold.ca"
                    rel="noreferrer"
                    target={"_blank"}
                  >
                    Visit Threshold
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Projects;
