import "./Contact.css";
import emailjs from "@emailjs/browser";
import React, { useRef, useState } from "react";

const Contact = () => {
  const [sucessMessage, setSucessMessage] = useState("");

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_xwtcx39",
        "portfolio_ID",
        form.current,
        "aHhYePbxtnTQMmnIN"
      )
      .then((result) => {
        setSucessMessage("Your message has been sucessfully sent!");
      })
      .catch((err) => console.error("Something went wrong. Please try again!"));

    e.target.reset();
  };

  return (
    <div id="contact" className="contacts">
      <div className="text-center header-information">
        <h1>Contact me</h1>
        <p>
          Please fill out the form and I'll contact you as soon as possible.
        </p>
        <span className="sucess-message">{sucessMessage}</span>
      </div>
      <div className="container">
        <form ref={form} onSubmit={sendEmail}>
          <div className="row justify-content-center">
            <div className="col-md-6 col-xs-12">
              <div className="text-center">
                <input
                  type="text"
                  id="name"
                  className="form-control"
                  placeholder="Name"
                  name="name"
                  required
                />
                <div className="line"></div>
              </div>

              <div className="text-center">
                <input
                  type="tel"
                  className="form-control"
                  placeholder="Phone Number"
                  name="phone"
                />
                <div className="line"></div>
              </div>

              <div className="text-center">
                <input
                  type="email"
                  className="form-control"
                  placeholder="Email"
                  name="email"
                  required
                />
                <div className="line"></div>
              </div>

              <div className="text-center">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Subject"
                  name="subject"
                  required
                />
                <div className="line"></div>
              </div>
              <div className="text-center">
                <textarea
                  type="text"
                  className="form-control"
                  placeholder="Message.."
                  name="message"
                  required
                ></textarea>
                <div className="line"></div>
              </div>
              <button className="btn-main-offer contact-btn" type="submit">
                Send Message
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Contact;
