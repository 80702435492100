import React from "react";
import "./Header.css";
import Typewriter from 'typewriter-effect';

const Header = (props) => {
  return (
    <div id="home" className="header-wraper">
      <div className="main-info">
        <h1>
          Devon <span>Hurteau</span>
        </h1>
        <h3>
        <Typewriter className="typist"
          options={{
            strings: ["Junior Web Developer"],
            autoStart: true,
            loop: true,
          }}
        />
        </h3>
      </div>
    </div>
  );
};

export default Header;
